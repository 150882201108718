import { useEffect, useRef, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import TopMenu from '../TopMenu';
import Table from '../../../Component/Table';
import columns from '../../../Data/Columns/Floggers';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../../Utils/CommonUtils';
import { Toast } from 'primereact/toast';
import AddFloggerDialog from './AddFloggerDialog';
import axios from 'axios';

const globalFilterFields = ['channelName', 'channelLink'];

const Floggers = () => {
    const [filters, setFilters] = useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
    const [floggers, setFloggers] = useState([]);
    const [isAddDialogShown, setIsAddDialogShown] = useState(false);
    const navigate = useNavigate();
    const toast = useRef(null);

    useEffect(() => {
        const fetchUsers = async () => {
            const data = await fetchData(`${process.env.REACT_APP_API_URL}/floggers`, navigate, toast);
            setFloggers(data);
        };

        fetchUsers();
    }, [navigate]);

    const addFlogger = async(channelName, channelLink, channelThumbnail) => {
        try {
            const formData = new FormData();
            formData.append('channelName', channelName);
            formData.append('channelLink', channelLink);
            formData.append('channelThumbnail', channelThumbnail);
            const token = window.localStorage.getItem('token');
            const { data: newFlogger } = await axios.post(`${process.env.REACT_APP_API_URL}/floggers/`, formData, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }});
            setFloggers([
                newFlogger,
                ...floggers
            ]);
            toast.current.show({ severity:'success', summary: 'Success', detail: `${newFlogger.channelName} is added!`, life: 5000 });
            setIsAddDialogShown(false);
        } catch (error) {
            toast.current.show({ severity:'error', summary: 'Error', detail: `Add new data is failed: ${error.message}`, life: 5000 });

        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <TopMenu currentIndex={2} />
            <div className='content'>
                <Table
                    title='Floggers'
                    filters={filters}
                    setFilters={setFilters}
                    data={floggers}
                    columns={columns}
                    globalFilterFields={globalFilterFields}
                    hasAddButton={true}
                    setIsAddDialogShown={setIsAddDialogShown}
                />
            </div>
            <AddFloggerDialog isAddDialogShown={isAddDialogShown} setIsAddDialogShown={setIsAddDialogShown} onSubmit={addFlogger} />
        </div>
    );
};

export default Floggers;
