import { Button } from 'primereact/button';                                                  
import { InputText } from 'primereact/inputtext';                             
import './LoginPage.css';
import { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useRef(null);

    const login = async () => {
        try {
            setIsLoading(true);
            const { data: { token, name, isAdmin } } = await axios.post(`${process.env.REACT_APP_API_URL}/users/token`, { email, password });
            if (isAdmin) {
                window.localStorage.setItem('token', token);
                window.localStorage.setItem('name', name);
                return navigate('/admin', { replace: true });
            }
            toast.current.show({ severity:'error', summary: 'Error', detail: 'You are not authorized!', life: 5000 });
        } catch (error) {
            console.log(error)
            return toast.current.show({ severity:'error', summary: 'Error', detail: 'You are not authorized!', life: 5000 });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='login-page'>
            <Toast ref={toast} />
            <div className='card'>
                <h2>Login to Your Account</h2>
                <span className='label'>Email *</span>
                <InputText type='text' onChange={(event) => setEmail(event.target.value)} />
                <span className='label'>Password *</span>
                <InputText type='password' onChange={(event) => setPassword(event.target.value)} />
                <Button className='button' onClick={login} isLoading={isLoading}>Login</Button>
            </div>
        </div>
    );
};

export default LoginPage;
