import { Box, Flex, Link, Spacer, Text } from "@chakra-ui/react";
import logo from "../Assets/logo.png";

const Navbar = () => {
  const handleScroll = (sectionId) => {
    const target = document.getElementById(sectionId);
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      as="nav"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      bg="transparent"
      zIndex="1000"
      px="6"
      py="4"
      boxShadow="none"
    >
      <Flex alignItems="center">
        {/* Logo Section */}
        <Flex alignItems="center" color="white" fontSize="md">
          <img src={logo} alt="logo" width="60px" />
          <Text ml="2" color="rgb(228,163,83)">Kompas Kuliner</Text>
        </Flex>
        <Spacer />
        {/* Navigation Links */}
        <Flex gap="6" mr="5" display={{ base: "none", md: "flex" }}>
          <Link
            onClick={() => handleScroll("hero-section")}
            fontSize="sm"
            color="rgb(228,163,83)"
            _hover={{ color: "rgb(149,171,99)" }}
            cursor="pointer"
          >
            About
          </Link>
          <Link
            onClick={() => handleScroll("feature-section")}
            fontSize="sm"
            color="rgb(228,163,83)"
            _hover={{ color: "rgb(149,171,99)" }}
            cursor="pointer"
          >
            Features
          </Link>
          <Link
            onClick={() => handleScroll("download-section")}
            fontSize="sm"
            color="rgb(228,163,83)"
            _hover={{ color: "rgb(149,171,99)" }}
            cursor="pointer"
          >
            Download
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
