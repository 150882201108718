// import { MultiSelect } from 'primereact/multiselect';

// const nameFilterTemplate = (options) => {
//     return (
//         <MultiSelect
//             value={options.value}
//             options={reviews}
//             onChange={(e) => options.filterApplyCallback(e.value)}
//             optionLabel='name'
//             placeholder='Any'
//             className='p-column-filter'
//             style={{ minWidth: '14rem' }}
//         />
//     );
// };

// const channelNameFilterTemplate = (options) => {
//     return (
//         <MultiSelect
//             value={options.value}
//             options={reviews}
//             onChange={(e) => {options.filterApplyCallback(e.value)}}
//             optionLabel='channelName'
//             placeholder='Any'
//             className='p-column-filter'
//             style={{ minWidth: '14rem' }}
//         />
//     );
// };

const channelNameBodyTemplate = (rowData) => {
    const { channelName, channelThumbnail } = rowData;

    return (
        <div className='youtube-channel'>
            {/* <img alt={channelName} src={channelThumbnail} width='30' /> */}
            <span>{channelName}</span>
        </div>
    );
};

const googleMapsBodyTemplate = (rowData) => {
    return (
        <a className='maps-icon' href={rowData.googleMapsLink} target='_blank' rel='noreferrer'><i className='pi pi-map-marker'/></a>
    )
};

const youtubeBodyTemplate = (rowData) => {
    return (
        <a className='youtube-icon' href={rowData.youtubeVideoLink} target='_blank' rel='noreferrer'><i className='pi pi-youtube'/></a>
    );
};

const dateBodyTemplate = (rowData) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    return new Date(rowData.date).toLocaleDateString('en-US', options);
};

const columns = [
    {
        field: '_id',
        header: 'ID'
    },
    {
        field: 'channelName',
        header: 'Channel Name',
        sortable: true,
        // filter: true,
        // filterElement: channelNameFilterTemplate,
        showFilterMatchModes: false,
        body: channelNameBodyTemplate
    },
    {
        field: 'name',
        header: 'Name',
        sortable: true,
        // filter: true,
        // filterElement: nameFilterTemplate,
        showFilterMatchModes: false,
    },
    {
        field: 'longitude',
        header: 'Longitude'
    },
    {
        field: 'latitude',
        header: 'Latitude'
    },
    {
        field: 'googleMapsLink',
        header: 'Google Maps',
        body: googleMapsBodyTemplate
    },
    {
        field: 'youtubeVideoLink',
        header: 'Youtube',
        body: youtubeBodyTemplate
    },
    {
        field: 'date',
        header: 'Upload Date',
        sortable: true,
        body: dateBodyTemplate
    },
];

export default columns;
