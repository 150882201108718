import { useCallback, useEffect, useRef, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import TopMenu from '../TopMenu';
import Table from '../../../Component/Table';
import columns from '../../../Data/Columns/Reviews';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../../Utils/CommonUtils';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { FileUpload } from 'primereact/fileupload';
import axios from 'axios';

const globalFilterFields = ['channelName', 'name'];

const Reviews = () => {
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // channelName: { value: null, matchMode: FilterMatchMode.IN },
        // name: { value: null, matchMode: FilterMatchMode.IN },
    });
    const [reviews, setReviews] = useState([]);
    const [floggers, setFloggers] = useState([]);
    const [successUploadNames, setSuccessUploadNames] = useState([]);
    const [failedUploadNames, setFailedUploadNames] = useState([]);
    const [updatedUploadNames, setUpdatedUploadNames] = useState([]);
    const [duplicatedUploadNames, setDuplicatedUploadNames] = useState([]);
    const [isChooseFloggerShown, setIsChooseFloggerShown] = useState(false);
    const [selectedFlogger, setSelectedFlogger] = useState(null);
    const navigate = useNavigate();
    const toast = useRef(null);
    
    const fetchReviews = useCallback(async () => {
        const data = await fetchData(`${process.env.REACT_APP_API_URL}/places`, navigate, toast);
        console.log(data)
        const reviews = data?.flatMap((item) => item.reviews);
        const sortedByChannelName = reviews?.sort((firstReview, secondReview) => {
            if (firstReview.channelName < secondReview.channelName) {
                return -1;
            }
            if (firstReview.channelName > secondReview.channelName) {
                return 1;
            }
            return 0;
        });
        setReviews(sortedByChannelName);
    }, [navigate, toast]);

    useEffect(() => {
        const fetchFloggers = async () => {
            const data = await fetchData(`${process.env.REACT_APP_API_URL}/floggers`, navigate, toast);
            setFloggers(data);
        };

        fetchReviews();
        fetchFloggers();
    }, [fetchReviews, navigate]);

    const customUpload = async (event) => {
        const formData = new FormData();
        for (let file of event.files) {
            formData.append('file', file);
        }
        formData.append('selectedFloggerId', selectedFlogger?._id); 
    
        try {
            const token = window.localStorage.getItem('token');
            const { data: { successNames, failedNames, duplicatedNames, updatedNames } } = await axios.post(`${process.env.REACT_APP_API_URL}/reviews/`, formData, { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }});
            toast.current.show({ severity:'success', summary: 'Success', detail: `Upload reviews for ${selectedFlogger?.channelName} is completed`, life: 5000 });
            setSuccessUploadNames(successNames);
            setFailedUploadNames(failedNames);
            setDuplicatedUploadNames(duplicatedNames);
            setUpdatedUploadNames(updatedNames);
            setSelectedFlogger(null);
            setIsChooseFloggerShown(false);
            await fetchReviews();
        } catch (error) {
            toast.current.show({ severity:'error', summary: 'Error', detail: `Upload reviews for ${selectedFlogger?.channelName} is error. Please try again.`, life: 5000 });
        };
    }

    return (
        <div>
            <Toast ref={toast} />
            <TopMenu currentIndex={3}/>
            <div className='content'>
                <Table title='Reviews' filters={filters} setFilters={setFilters} data={reviews} columns={columns} globalFilterFields={globalFilterFields} hasButtons={true} setIsChooseFloggerShown={setIsChooseFloggerShown} />
            </div>
            <Dialog
                header='Choose Flogger'
                visible={isChooseFloggerShown}
                onHide={() => { if (!isChooseFloggerShown) return; setIsChooseFloggerShown(false); setSelectedFlogger(null); }}
            >
                <ListBox filter value={selectedFlogger} onChange={(e) => setSelectedFlogger(e.value)} options={floggers} optionLabel='channelName' className='w-full md:w-14rem' listStyle={{ maxHeight: '250px' }} />
                {
                    selectedFlogger &&
                    <>
                        <Toast ref={toast}></Toast>
                        <FileUpload
                            mode='basic'
                            name='reviews[]'
                            url=''
                            accept='.csv'
                            maxFileSize={1000000}
                            chooseLabel='Choose file'
                            customUpload
                            uploadHandler={customUpload}
                            style={{ textAlign: 'center', marginTop: '10px'}}
                        />
                    </>
                }
            </Dialog>
            <Dialog
                header='Upload Reviews Result'
                visible={successUploadNames?.length > 0 || failedUploadNames?.length > 0 || updatedUploadNames?.length > 0 || duplicatedUploadNames?.length > 0}
                onHide={() => { setSuccessUploadNames([]); setFailedUploadNames([]); setUpdatedUploadNames([]); setDuplicatedUploadNames([]); }}
                style={{ width: '50vw' }}
            >
                <h4>Success ({successUploadNames.length})</h4>
                {successUploadNames.length > 0 ? <ListBox filter options={successUploadNames} optionLabel='' className='w-full md:w-14rem' listStyle={{ maxHeight: '250px' }} /> : <p>No data</p>}
                <hr />
                <h4>Failed ({successUploadNames.length})</h4>
                {failedUploadNames.length > 0 ? <ListBox filter options={failedUploadNames} optionLabel='' className='w-full md:w-14rem' listStyle={{ maxHeight: '250px' }} /> : <p>No data</p>}
                <hr />
                <h4>Updated ({updatedUploadNames.length})</h4>
                {updatedUploadNames.length > 0 ? <ListBox filter options={updatedUploadNames} optionLabel='' className='w-full md:w-14rem' listStyle={{ maxHeight: '250px' }} /> : <p>No data</p>}
                <hr />
                <h4>Duplicated ({duplicatedUploadNames.length})</h4>
                {duplicatedUploadNames.length > 0 ? <ListBox filter options={duplicatedUploadNames} optionLabel='' className='w-full md:w-14rem' listStyle={{ maxHeight: '250px' }} /> : <p>No data</p>}
            </Dialog>
        </div>
    );
};

export default Reviews;
