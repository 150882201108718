import { classNames } from "primereact/utils";

const isAdmin = (rowData) => {
    return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.isAdmin, 'false-icon pi-times-circle': !rowData.isAdmin })}></i>;
};

const isEmailVerified = (rowData) => {
    return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.isEmailVerified, 'false-icon pi-times-circle': !rowData.isEmailVerified })}></i>;
};

const columns = [
    {
        field: '_id',
        header: 'ID'
    },
    {
        field: 'name',
        header: 'Name',
        sortable: true
    },
    {
        field: 'email',
        header: 'Email',
        sortable: true
    },
    {
        field: 'isEmailVerified',
        header: 'Email Verified',
        body: isEmailVerified
    },
    {
        field: 'isAdmin',
        header: 'Admin Access',
        dataType: 'boolean',
        body: isAdmin
    },
];

export default columns;
