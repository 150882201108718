import { useRef, useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../Utils/CommonUtils';
import { Toast } from 'primereact/toast';

const TopMenu = ({ currentIndex }) => {
    const [activeIndex, setActiveIndex] = useState(currentIndex);
    const navigate = useNavigate();
    const toast = useRef(null);

    const logout = async () => {
        const {
            message
        } = await fetchData(`${process.env.REACT_APP_API_URL}/users/logout`);
        navigate('/login', { replace: true });
        toast.current.show({ severity:'success', summary: 'Success', detail: message, life: 5000 });
    };

    const items = [
        { label: 'Home', icon: 'pi pi-home', command: () => navigate('/admin') },
        { label: 'Users', icon: 'pi pi-users', command: () => navigate('/users') },
        { label: 'Floggers', icon: 'pi pi-youtube', command: () => navigate('/floggers') },
        { label: 'Reviews', icon: 'pi pi-video', command: () => navigate('/reviews') },
        { label: 'Logout', icon: 'pi pi-sign-out', command: logout }
    ];
    
    return (
        <>
            <Toast ref={toast} />
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
        </>
    )
};

export default TopMenu;
