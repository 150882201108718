import './AdminPage.css';
import TopMenu from './TopMenu';

const AdminPage = () => {
    const name = window.localStorage.getItem('name');

    return (
        <div>
            <TopMenu currentIndex={0} />
            <div className='content'>
                <span>Welcome back, {name}!</span>
            </div>
        </div>
    );
};

export default AdminPage;
        