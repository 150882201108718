import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import file from '../Assets/reviews-template.csv';

const Table = ({ title, filters, setFilters, data, columns, globalFilterFields, hasButtons, setIsChooseFloggerShown, hasAddButton, setIsAddDialogShown }) => {
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleDownloadExample = () => {
        const link = document.createElement('a');
        link.href = file;
        link.download = 'reviews-template.csv';
        link.click();
    };

    const renderHeader = () => {
        return (
            <div className='header'>
                <h4>{title}</h4>
                <div className='right'>
                    {
                        hasAddButton &&
                        <Button type='button' icon='pi pi-plus' tooltip='Add new data' rounded onClick={() => setIsAddDialogShown(true)} />
                    }
                    {
                        hasButtons && 
                        <>
                            <Button type='button' icon='pi pi-download' tooltip='Download example file' rounded onClick={handleDownloadExample} />
                            <Button type='button' icon='pi pi-upload' tooltip='Upload file' rounded onClick={() => setIsChooseFloggerShown(true)} />
                        </>
                    }
                    <IconField iconPosition='left'>
                        <InputIcon className='pi pi-search' />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Keyword Search' />
                    </IconField>
                </div>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <>
            <DataTable
                size='normal'
                header={header}
                filters={filters}
                filterDelay='menu'
                globalFilterFields={globalFilterFields}
                emptyMessage='No data found.'
                removableSort
                stripedRows
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                value={data}
                tableStyle={{ minWidth: '50rem' }}
            >
                {columns.map((column, index) => (
                    <Column
                        key={column.field}
                        field={column.field}
                        sortable={column.sortable}
                        header={column.header}
                        dataType={column.dataType}
                        body={column.body}
                        filter={column.filter}
                        filterElement={column.filterElement}
                        showFilterMatchModes={column.showFilterMatchModes}
                    />
                ))}
            </DataTable>
        </>
    );
};

export default Table;
