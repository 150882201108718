const youtubeBodyTemplate = (rowData) => {
    return (
        <a className='youtube-icon' href={rowData.channelLink} target='_blank' rel='noreferrer'><i className='pi pi-youtube'/></a>
    );
};

const channelBodyTemplate = (rowData) => {
    const { channelName, channelThumbnail } = rowData;

    return (
        <div className='youtube-channel'>
            {/* <img alt={channelName} src={channelThumbnail} width='35' /> */}
            <span>{channelName}</span>
        </div>
    );
};

const columns = [
    {
        field: '_id',
        header: 'ID'
    },
    {
        field: 'channelName',
        header: 'Channel Name',
        sortable: true,
        body: channelBodyTemplate,
    },
    {
        field: 'channelLink',
        header: 'Channel Link',
        body: youtubeBodyTemplate
    }
];

export default columns;