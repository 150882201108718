import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { Flex } from '@chakra-ui/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import screenshot1 from '../Assets/edited1.png';
import screenshot2 from '../Assets/edited2.png';
import screenshot3 from '../Assets/edited3.png';
import screenshot4 from '../Assets/edited4.png';

export default function Screenshots() {
  return (
    <Flex width={{ base: "60vh", xl: "100vh" }} justifyContent="left">
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        effect="coverflow"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
        }}
        pagination={false}
        navigation={false}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="screenshots"
        // style={{ width: "100%", height: "300px" }}
      >
        <SwiperSlide>
          <img src={screenshot1} alt="screenshot 1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={screenshot2} alt="screenshot 2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={screenshot3} alt="screenshot 3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={screenshot4} alt="screenshot 4" />
        </SwiperSlide>
      </Swiper>
    </Flex>
  );
}
