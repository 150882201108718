import router from './Router/Router';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-orange/theme.css';
import 'swiper/css/bundle';

function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;
