import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';

const AddFloggerDialog = ({isAddDialogShown, setIsAddDialogShown, onSubmit}) => {
    const [channelName, setChannelName] = useState('');
    const [channelLink, setChannelLink] = useState('');
    const [channelThumbnail, setChannelThumbnail] = useState(null);

    const handleFileUpload = (e) => {
        setChannelThumbnail(e.files[0]);
    };

    const handleSave = () => {
        onSubmit(channelName, channelLink, channelThumbnail);
        setChannelName('');
        setChannelLink('');
        setChannelThumbnail(null);
    };

    const addDialogFooter = (
        <div>
          <Button label='Cancel' icon='pi pi-times' onClick={() => setIsAddDialogShown(false)} className='p-button-text' />
          <Button label='Save' icon='pi pi-check' onClick={handleSave} autoFocus />
        </div>
    );

    return (
        <Dialog
            header='Add new data'
            visible={isAddDialogShown}
            onHide={() => { if (!isAddDialogShown) return; setIsAddDialogShown(false); }}
            style={{ width: '50vw' }}
            footer={addDialogFooter}
        >
            <div className='p-fluid'>
                <div className='p-field field'>
                    <label htmlFor='channelName'>Channel Name</label>
                    <InputText id='channelName' value={channelName} onChange={(e) => setChannelName(e.target.value)} />
                </div>
                <div className='p-field field'>
                    <label htmlFor='channelLink'>Channel Link</label>
                    <InputText id='channelLink' value={channelLink} onChange={(e) => setChannelLink(e.target.value)} />
                </div>
                <div className='p-field field'>
                    <label htmlFor='channelThumbnail'>Channel Thumbnail</label>
                    <FileUpload name='channelThumbnail' accept='image/*' customUpload uploadHandler={handleFileUpload} auto />
                </div>
            </div>
        </Dialog>
    );
};

export default AddFloggerDialog;
