import { useEffect, useRef, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import Table from '../../../Component/Table';
import TopMenu from '../TopMenu';
import columns from '../../../Data/Columns/User';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { fetchData } from '../../../Utils/CommonUtils';

const Users = () => {
    const globalFilterFields = ['name', 'email'];
    const [filters, setFilters] = useState({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const toast = useRef(null);

    useEffect(() => {
        const fetchUsers = async () => {
            const data = await fetchData(`${process.env.REACT_APP_API_URL}/users`, navigate, toast);
            setUsers(data);
        };

        fetchUsers();
    }, [navigate]);

    return (
        <div>
            <Toast ref={toast} />
            <TopMenu currentIndex={1} />
            <div className='content'>
                <Table title='Users' filters={filters} setFilters={setFilters} data={users} columns={columns} globalFilterFields={globalFilterFields} />
            </div>
        </div>
    );
};

export default Users;
