import axios from 'axios';

export const fetchData = async (url, navigate, toast) => {
    try {
        const token = window.localStorage.getItem('token');
        const { data } = await axios.get(url, { headers: { 'Authorization': `Bearer ${token}` }});
        return data;
    } catch (error) {
        if (error.response?.status === 401) {
            window.localStorage.clear();
            return navigate('/login', { replace: true });
        }
        const message = error.response ? error.response.data.message : error.message;
        console.log(message)
        toast.current.show({severity:'error', summary: 'Error', detail: message, life: 5000});
    }
};